//               \||/
//                |  @___oo
//      /\  /\   / (__,,,,|
//     ) /^\) ^\/ _)
//     )   /^\/   _)
//     )   _ /  / _)
// /\  )/\/ ||  | )_)
//<  >      |(,,) )__)
// ||      /    \)___)\
// | \____(      )___) )___
//  \______(_______;;; __;;;
//
// WARNING! Here be dragons!
// All of this code was written hastily on a Saturday morning.
// Below you will see copy pasting, plenty of unnecessary duplication,
// difficult-to-follow state mutations & just generally *bad code*.
// But who cares! It works, right? :)

import './App.css';
import { useState } from "react";
import Countdown from 'react-countdown';
import axios from 'axios';
// original date: may 15, 2022
const gameStartDate = new Date('5/15/2022');
const todaysDate = new Date();
const diffTime = Math.abs(todaysDate - gameStartDate);
const konami = require('konami');
let GAME = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
let realGame = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

const egge = document.querySelector('#easter-egg');
const showEgge = () => {
  egge.style.display = 'block';
}
const hideEgge = () => {
  egge.style.display = 'none';
}

const devMode = window.location.href.includes("localhost");
// const devMode = false;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

new konami(async () => {
  showEgge();
  await delay(2200);
  hideEgge();
});

const urlSearchParams = new URLSearchParams(window.location.search);

const params = Object.fromEntries(urlSearchParams.entries());



console.log("Game start date:", gameStartDate);
console.log("Current date:", todaysDate);

if (params.fpg) {
  GAME = parseInt(params.fpg);
  console.log("Game # (playing an old day):", GAME);
}
else {
  console.log("Game # for today:", GAME);
}

if (devMode == false) {
  window.history.pushState({}, document.title, window.location.pathname);
}

const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    window.location.reload(true);
  } else {
    return <span className="countdown-to-next-game">{hours <= 9 ? `0${hours}` : hours}h {minutes <= 9 ? `0${minutes}` : minutes}m {seconds <= 9 ? `0${seconds}` : seconds}s</span>;
  }
};


const answers = {
  1: { answer: "Mass Effect 3", release_year: 2012, metacritic_score: '89%', genre: 'Action RPG', console_platform: 'PC, PS3, Xbox 360', developer: 'Bioware' },
  2: { answer: "The Legend of Zelda: Ocarina of Time", release_year: 1998, metacritic_score: '99%', genre: 'Action/Adventure', console_platform: 'Nintendo 64', developer: 'Nintendo' },
  // tuesday the 17th of may
  3: { answer: "Outer Wilds", release_year: 2019, metacritic_score: '85%', genre: 'Open World/Puzzle', console_platform: 'PC, PS4, Xbox One, Switch', developer: 'Mobius Digital' },
  4: { answer: "Horizon Zero Dawn", release_year: 2017, metacritic_score: '89%', genre: 'Action RPG', console_platform: 'PS4', developer: 'Guerrilla Games' },
  5: { answer: "The Secret of Monkey Island", release_year: 1990, metacritic_score: 'Metacritic didn\'t exist yet! But probably 100% ;)', genre: 'Point & Click Adventure', console_platform: 'many', developer: 'LucasArts' },
  6: { answer: "Grand Theft Auto 3", release_year: 2001, metacritic_score: '93%', genre: 'Action/Adventure', console_platform: 'PS2', developer: 'Rockstar Games' },
  7: { answer: "Resident Evil 4", release_year: 2005, metacritic_score: '96%', genre: 'Survival Horror', console_platform: 'GameCube', developer: 'Capcom Production Studio 4' },
  8: { answer: "Portal 2", release_year: 2011, metacritic_score: '95%', genre: 'Puzzle', console_platform: 'PC, PS3, Xbox 360', developer: 'Valve' },
  9: { answer: "Shadow of the Colossus", release_year: 2005, metacritic_score: '91%', genre: 'Action/Adventure', console_platform: 'PS2', developer: 'SCE Japan Studio' },
  // tuesday the 24th of may
  10: { answer: "Mario Golf", release_year: 1999, metacritic_score: '91%', genre: 'Sports', console_platform: 'Nintendo 64', developer: 'Nintendo' },
  11: { answer: "Call of Duty: Modern Warfare 2", release_year: 2009, metacritic_score: '86%', genre: 'Shooter', console_platform: 'PC, PS3, Xbox 360', developer: 'Infinity Ward' },
  12: { answer: "The Witness", release_year: 2016, metacritic_score: '87%', genre: 'Puzzle', console_platform: 'PC, PS4', developer: 'Thekla Inc' },
  13: { answer: "Halo: Combat Evolved", release_year: 2001, metacritic_score: '97%', genre: 'Shooter', console_platform: 'Xbox', developer: 'Bungie' },
  14: { answer: "Bloodborne", release_year: 2015, metacritic_score: '92%', genre: 'Action/Adventure', console_platform: 'PS4', developer: 'From Software' },
  15: { answer: "Forza Horizon 5", release_year: 2021, metacritic_score: '92%', genre: 'Racing', console_platform: 'PC, Xbox Series X|S', developer: 'Playground Games' },
  16: { answer: "Super Meat Boy", release_year: 2010, metacritic_score: '87%', genre: 'Platformer', console_platform: 'Xbox 360', developer: 'Team Meat' },
  // tuesday the 31st of may
  17: { answer: "The Elder Scrolls IV: Oblivion", release_year: 2006, metacritic_score: '94%', genre: 'Action RPG', console_platform: 'PC, Xbox 360', developer: 'Bethesda Game Studios' },
  18: { answer: "BioShock Infinite", release_year: 2013, metacritic_score: '94%', genre: 'Shooter', console_platform: 'PC, PS3, Xbox 360', developer: 'Irrational Games' },
  19: { answer: "Chrono Trigger", release_year: 1995, metacritic_score: 'Too old for metacritic!', genre: 'JRPG', console_platform: 'SNES', developer: 'Square' },
  20: { answer: "Furi", release_year: 2016, metacritic_score: '76%', genre: 'Action/Adventure, Hack n\' Slash, Bullet Hell', console_platform: 'PC, PS4', developer: 'The Game Bakers' },
  21: { answer: "Yakuza 0", release_year: "2015 (Japan), 2017 (NA/EU)", metacritic_score: '85%', genre: 'Action/Adventure', console_platform: 'PS3/PS4 (JP), PS4 (NA/EU)', developer: 'Ryu Ga Gotoku Studio/Sega' },
  22: { answer: "Dead Cells", release_year: "2018", metacritic_score: '89%', genre: 'Action Platformer', console_platform: 'Linux/Mac/Windows, Nintendo Switch, PlayStation 4, and Xbox One', developer: 'Motion Twin' },
  23: { answer: "Persona 5", release_year: "2016 (JP), 2017 (Worldwide)", metacritic_score: '93%', genre: 'JRPG', console_platform: 'PS3, PS4', developer: 'Atlus' },
  // tuesday the 7th of june
  24: { answer: "NieR:Automata", release_year: "2017", metacritic_score: '88%', genre: 'Action RPG / Religious Experience', console_platform: 'PC, PS4', developer: 'PlatinumGames' },
  25: { answer: "Super Mario 64", release_year: "1996", metacritic_score: "94%", genre: "Platformer", console_platform: "N64", developer: "Nintendo" },
  26: { answer: "The Last of Us", release_year: "2013", metacritic_score: "95%", genre: "Action/Adventure", console_platform: "PS3", developer: "Naughty Dog" },
  27: { answer: "Left 4 Dead", release_year: "2008", metacritic_score: "89%", genre: "Shooter", console_platform: "PC, Xbox 360", developer: "Valve South" },
  28: { answer: "SimCopter", release_year: "1996", metacritic_score: "Too old for metacritic!", genre: "Flight Simulator", console_platform: "PC", developer: "Maxis" },
  29: { answer: "Diablo", release_year: "1997", metacritic_score: "94%", genre: "ARPG", console_platform: "PC", developer: "Blizzard Entertainment" },
  30: { answer: "Dark Souls", release_year: "2011", metacritic_score: "89%", genre: "Action/Adventure", console_platform: "PS3, Xbox 360", developer: "From Software Inc." },
  // tuesday the 14th of june
  31: { answer: "Ghostwire: Tokyo", release_year: "2022", metacritic_score: "77%", genre: "Action/Adventure", console_platform: "PC, PS5", developer: "Tango Gameworks" },
  32: { answer: "Night In The Woods", release_year: "2017", metacritic_score: "88%", genre: "Story-focused Adventure? What even are genres, really", console_platform: "PC, PS4", developer: "Secret Lab & Infinite Fall" },
  33: { answer: "The Legend of Zelda: Breath of the Wild", release_year: "2017", metacritic_score: "97%", genre: "Action/Adventure", console_platform: "Switch, Wii U", developer: "Nintendo" },
  34: { answer: "Fable", release_year: "2004", metacritic_score: "85%", genre: "Action RPG", console_platform: "Xbox", developer: "Big Blue Box Studios" },
  35: { answer: "Dota 2", release_year: "2013", metacritic_score: "90%", genre: "MOBA", console_platform: "PC", developer: "Valve" },
  36: { answer: "World of Warcraft", release_year: "2004", metacritic_score: "93%", genre: "MMO", console_platform: "PC", developer: "Blizzard Entertainment" },
  37: { answer: "Dead Space", release_year: "2008", metacritic_score: "86%", genre: "Survival Horror", console_platform: "PS3, Xbox 360, PC", developer: "EA Redwood Shores" },
  // tuesday the 21st of june
  38: { answer: "Sea of Thieves", release_year: "2018", metacritic_score: "67% (this is a crime!)", genre: "Action/Adventure", console_platform: "PC, Xbox One", developer: "Rare" },
  39: { answer: "Monster Hunter: World", release_year: "2018", metacritic_score: "90%", genre: "Action RPG", console_platform: "PS4, Xbox One", developer: "Capcom" },
  40: { answer: "Hollow Knight", release_year: "2017", metacritic_score: "87%", genre: "Platformer", console_platform: "PC", developer: "Team Cherry" },
  41: { answer: "Destiny 2", release_year: "2017", metacritic_score: "83%", genre: "Looter Shooter", console_platform: "PC, PS4, Xbox One", developer: "Bungie" },
  42: { answer: "Animal Crossing: New Horizons", release_year: "2020", metacritic_score: "90%", genre: "\"Simulation\", apparently? 🤔", console_platform: "Switch", developer: "Nintendo" },
  43: { answer: "The Witcher 3: Wild Hunt", release_year: "2015", metacritic_score: "93%", genre: "Action RPG", console_platform: "PC, PS4, Xbox One", developer: "CD Project Red" },
  44: { answer: "GRIS", release_year: "2018", metacritic_score: "84%", genre: "Platformer", console_platform: "PC, Switch", developer: "Nomada Studios" },
  // tuesday the 28th of june
  45: { answer: "Returnal", release_year: "2021", metacritic_score: "86%", genre: "Action/Adventure", console_platform: "PS5", developer: "Housemarque" },
  46: { answer: "Super Mario RPG: Legend of the Seven Stars", release_year: "1996", metacritic_score: "Too old for metacritic!", genre: "RPG", console_platform: "SNES", developer: "Square (not Nintendo? 😲)" },
  47: { answer: "Code Vein", release_year: "2019", metacritic_score: "70%", genre: "Action/Adventure", console_platform: "PC, PS4, Xbox One", developer: "Bandai Namco Entertainment" },
  48: { answer: "Deep Rock Galactic", release_year: "2020", metacritic_score: "85%", genre: "Shooter/RPG/Dwarf sim", console_platform: "PC, Xbox One", developer: "Ghost Ship Games" },
  49: { answer: "Apex Legends", release_year: "2019", metacritic_score: "88%", genre: "Shooter/Battle Royale", console_platform: "PC, PS4, Xbox One", developer: "Respawn Entertainment" },
  50: { answer: "Nidhogg", release_year: "2014", metacritic_score: "81%", genre: "Fighting game, sorta?", console_platform: "PC (later ported to PS4 & Vita)", developer: "Meshoff" },
  51: { answer: "Sid Meier's Civilization VI", release_year: "2016", metacritic_score: "88%", genre: "Turn-based Strategy", console_platform: "PC", developer: "Firaxis Games" },
  // tuesday the 5th of July
  52: { answer: "Katamari Damacy", release_year: "2004", metacritic_score: "86%", genre: "Puzzle/Action", console_platform: "PS2", developer: "Namco" },
  53: { answer: "Overwatch", release_year: "2016", metacritic_score: "91%", genre: "Hero Shooter", console_platform: "PC, PS4, Xbox One", developer: "Blizzard Entertainment" },
  54: { answer: "Inside", release_year: "2016", metacritic_score: "87%", genre: "Platformer", console_platform: "PC, PS4, Xbox One", developer: "Playdead" },
  55: { answer: "Batman: Arkham Asylum", release_year: "2009", metacritic_score: "91%", genre: "Action Adventure", console_platform: "PC, PS3, Xbox 360", developer: "Rocksteady Studios" },
  56: { answer: "Dragon Age: Inquisition", release_year: "2014", metacritic_score: "85%", genre: "RPG", console_platform: "PC, PS3, PS4, Xbox 360, Xbox One", developer: "BioWare" },
  57: { answer: "DIRT 4", release_year: "2017", metacritic_score: "78%", genre: "Racing", console_platform: "PC, PS4, Xbox One", developer: "Codemasters" },
  58: { answer: "Spore", release_year: "2008", metacritic_score: "84%", genre: "🤮🤮 ? Metacritic lists the genre as 'breeding' ? 🤮🤮", console_platform: "PC", developer: "Maxis" },
  // tuesday the 12th of July
  59: { answer: "Burnout 3: Takedown", release_year: "2004", metacritic_score: "94%", genre: "Arcade Racing", console_platform: "PS2, Xbox", developer: "Criterion Games" },
  60: { answer: "The Sims", release_year: "2000", metacritic_score: "92%", genre: "Simulaton", console_platform: "PC", developer: "Maxis" },
  61: { answer: "Star Wars: Knights of the Old Republic", release_year: "2003", metacritic_score: "93%", genre: "RPG", console_platform: "PC, Xbox", developer: "BioWare" },
  62: { answer: "Super Smash Bros. Ultimate", release_year: "2018", metacritic_score: "93%", genre: "Platform Fighter", console_platform: "Switch", developer: "Nintendo" },
  63: { answer: "Goldeneye 007", release_year: "1997", metacritic_score: "96%", genre: "Shooter", console_platform: "Nintendo 64", developer: "Rare" },
  64: { answer: "Hotline Miami", release_year: "2012", metacritic_score: "85%", genre: "Action", console_platform: "PC", developer: "Dennaton Games" },
  65: { answer: "Rock Band", release_year: "2007", metacritic_score: "92%", genre: "Music", console_platform: "Xbox 360, PS3", developer: "Harmonix" },
  // tuesday the 19th of July
  66: { answer: "Tony Hawk's Pro Skater", release_year: "1999", metacritic_score: "92%", genre: "Sports, Skateboarding", console_platform: "Playstation 1", developer: "Neversoft" },
  67: { answer: "Super Mario Galaxy", release_year: "2007", metacritic_score: "97%", genre: "Platformer", console_platform: "Wii", developer: "Nintendo" },
  68: { answer: "Return of the Obra Dinn", release_year: "2018", metacritic_score: "89%", genre: "Puzzle", console_platform: "PC", developer: "Lucas Pope" },
  69: { answer: "Fortnite", release_year: "2017", metacritic_score: "81%", genre: "Shooter", console_platform: "PC, PS4, Xbox one", developer: "Epic Games" },
  70: { answer: "God of War", release_year: "2018", metacritic_score: "94%", genre: "Action/Adventure", console_platform: "PS4", developer: "Santa Monica Studio" },
  71: { answer: "The Elder Scrolls V: Skyrim", release_year: "2011", metacritic_score: "94%", genre: "RPG", console_platform: "PC, PS3, Xbox 360", developer: "Bethesda Game Studios" },
  72: { answer: "PUBG: Battlegrounds", release_year: "2017", metacritic_score: "86%", genre: "Shooter", console_platform: "PC", developer: "Bluehole/PUBG Studios" },
  // tuesday the 26th of July
  73: { answer: "Wii Sports", release_year: "2006", metacritic_score: "76%", genre: "Sports", console_platform: "Wii", developer: "Nintendo" },
  74: { answer: "Shovel Knight", release_year: "2014", metacritic_score: "85%", genre: "Platformer", console_platform: "PC, 3DS, Wii U", developer: "Yacht Club Games" },
  75: { answer: "XCOM: Enemy Unknown", release_year: "2012", metacritic_score: "89%", genre: "Strategy", console_platform: "PC, PS3, Xbox 360", developer: "Firaxis Games" },
  76: { answer: "Celeste", release_year: "2018", metacritic_score: "88%", genre: "Platformer", console_platform: "PC, Switch, PS4, Xbox One", developer: "Extremely OK Games" },
  77: { answer: "Divinity: Original Sin II", release_year: "2017", metacritic_score: "93%", genre: "RPG", console_platform: "PC", developer: "Larian Studios" },
  78: { answer: "Gone Home", release_year: "2013", metacritic_score: "86%", genre: "Narrative Exploration", console_platform: "PC", developer: "The Fullbright Company" },
  79: { answer: "Fallout: New Vegas", release_year: "2010", metacritic_score: "84%", genre: "Action RPG", console_platform: "PC, PS3, Xbox 360", developer: "Obsidian Entertainment" },
  // tuesday the 2nd of August
  80: { answer: "The Legend of Zelda: The Wind Waker", release_year: "2002", metacritic_score: "96%", genre: "Action/Adventure", console_platform: "GameCube", developer: "Nintendo" },
  81: { answer: "Kerbal Space Program", release_year: "2011", metacritic_score: "88%", genre: "Simulation", console_platform: "PC", developer: "Squad" },
  82: { answer: "Braid", release_year: "2008", metacritic_score: "93%", genre: "Platformer/Puzzle", console_platform: "Xbox 360", developer: "Number None" },
  83: { answer: "Dead Rising", release_year: "2006", metacritic_score: "85%", genre: "Action/Adventure", console_platform: "Xbox 360", developer: "Capcom Production Studio 1" },
  84: { answer: "Stardew Valley", release_year: "2016", metacritic_score: "89%", genre: "RPG", console_platform: "PC", developer: "ConcernedApe" },
  85: { answer: "Control", release_year: "2019", metacritic_score: "85%", genre: "Action/Adventure", console_platform: "PC, PS4, Xbox One", developer: "Remedy Entertainment" },
  86: { answer: "Minecraft", release_year: "2009", metacritic_score: "93%", genre: "Action/Adventure", console_platform: "PC", developer: "Mojang Studios" },
  // tuesday the 9th of August
  87: { answer: "Counter-Strike: Global Offensive", release_year: "2012", metacritic_score: "83%", genre: "Shooter", console_platform: "PC", developer: "Valve, Hidden Path Entertainment" },
  88: { answer: "Glover", release_year: "1998", metacritic_score: "Too old for metacritic!", genre: "Platformer", console_platform: "N64", developer: "Interactive Studios" },
  89: { answer: "Jak and Daxter: The Precursor Legacy", release_year: "2001", metacritic_score: "90%", genre: "Platformer", console_platform: "PS2", developer: "Naughty Dog" },
  90: { answer: "Papers, Please", release_year: "2013", metacritic_score: "85%", genre: "??? honestly ???", console_platform: "PC", developer: "3909 LLC" },
  91: { answer: "Pokemon Go", release_year: "2016", metacritic_score: "69%", genre: "Trainer/AR", console_platform: "Mobile", developer: "Niantic" },
  92: { answer: "Metroid Prime", release_year: "2002", metacritic_score: "97%", genre: "Action/Shooter", console_platform: "GameCube", developer: "Retro Studios" },
  93: { answer: "FTL: Faster Than Light", release_year: "2012", metacritic_score: "84%", genre: "Strategy", console_platform: "PC", developer: "Subset Games" },
  // tuesday the 16th of August
  94: { answer: "Diablo II", release_year: "2000", metacritic_score: "88%", genre: "Action RPG", console_platform: "PC", developer: "Blizzard North" },
  95: { answer: "Paper Mario: The Thousand-Year Door", release_year: "2004", metacritic_score: "87%", genre: "RPG", console_platform: "GameCube", developer: "Intelligent Systems" },
  96: { answer: "Transistor", release_year: "2014", metacritic_score: "83%", genre: "RPG", console_platform: "PC, PS4", developer: "Supergiant Games" },
  97: { answer: "Marvel's Spider-Man: Miles Morales", release_year: "2020", metacritic_score: "85%", genre: "Action/Adventure", console_platform: "PS5, PS4", developer: "Insomniac Games" },
  98: { answer: "Final Fantasy VIII", release_year: "1999", metacritic_score: "90%", genre: "RPG", console_platform: "PlayStation", developer: "Square" },
  99: { answer: "Street Fighter II: The World Warrior", release_year: "1991", metacritic_score: "Too old for metacritic!", genre: "Fighting", console_platform: "Arcade Machine", developer: "Capcom" },
  100: { answer: "Hunt: Showdown", release_year: "2019", metacritic_score: "81%", genre: "Shooter", console_platform: "PC", developer: "Crytek" },
  // tuesday the 23rd of August
  101: { answer: "Dead by Daylight", release_year: "2016", metacritic_score: "71%", genre: "Surival Horror/Asymmetric Multiplayer", console_platform: "PC", developer: "Behaviour Interactive" },
  102: { answer: "Uncharted: Drake's Fortune", release_year: "2007", metacritic_score: "88%", genre: "Action/Adventure", console_platform: "PS3", developer: "Naughty Dog" },
  103: { answer: "Far Cry 3", release_year: "2012", metacritic_score: "88%", genre: "Action/Adventure", console_platform: "PC, PS3, Xbox 360", developer: "Ubisoft Montreal" },
  104: { answer: "Dishonored", release_year: "2012", metacritic_score: "91%", genre: "Action/Adventure", console_platform: "PC, PS3, Xbox 360", developer: "Arkane Studios" },
  105: { answer: "Metal Gear Rising: Revengeance", release_year: "2013", metacritic_score: "83%", genre: "Action/Adventure", console_platform: "PC, PS3, Xbox 360", developer: "PlatinumGames & Kojima Productions" },
  106: { answer: "Risk of Rain 2", release_year: "2019", metacritic_score: "85%", genre: "Action", console_platform: "PC, PS, Switch, PS4, Xbox One", developer: "Hopoo games" },
  107: { answer: "Rollercoaster Tycoon", release_year: "1999", metacritic_score: "Too old for metacritic! Here's a different hint... you probably got this game in a cereal box :)", genre: "Simulation/Management", console_platform: "PC", developer: "Chris Sawyer" },
  // tuesday the 30th of August
  108: { answer: "Death Stranding", release_year: "2019", metacritic_score: "82%", genre: "Action/Adventure", console_platform: "PS4", developer: "Kojima Productions" },
  109: { answer: "Kingdom Hearts II", release_year: "2005 (JP), 2006 (Globally)", metacritic_score: "87%", genre: "Action RPG", console_platform: "PS2", developer: "Square Enix & Buena Vista Games" },
  110: { answer: "Devil May Cry 5", release_year: "2019", metacritic_score: "88%", genre: "Action/Adventure", console_platform: "PC, PS4, Xbox One", developer: "Capcom" },
  111: { answer: "Spyro the Dragon", release_year: "1998", metacritic_score: "Too old for metacritic!", genre: "Action Platformer", console_platform: "PlayStation", developer: "Insomniac Games" },
  112: { answer: "Okami", release_year: "2006", metacritic_score: "93%", genre:"Action/Adventure", console_platform: "PS2", developer: "Clover Studio" },
  113: { answer: "Bomberman Hero", release_year: "1998", metacritic_score: "Too old for metacritic!", genre: "Action Platformer", console_platform: "N64", developer: "Hudson Soft" },
  114: { answer: "LittleBigPlanet", release_year: "2008", metacritic_score: "95%", genre: "Action Platformer", console_platform: "PS3", developer: "Media Molecule" },
  // tuesday the 6th of September
  115: { answer: "Final Fantasy XIV Online: A Realm Reborn", release_year: "2013", metacritic_score: "83%", genre: "MMORPG", console_platform: "PC, PS3", developer: "Square Enix" },
  116: { answer: "Quake", release_year: "1996", metacritic_score: "94%", genre: "Shooter", console_platform: "PC", developer: "id Software" },
  117: { answer: "The Talos Principle", release_year: "2014", metacritic_score: "85%", genre: "Puzzle", console_platform: "PC", developer: "Croteam" },
  118: { answer: "The Stanley Parable", release_year: "2013", metacritic_score: "88%", genre: "Walking Simulator? Interactive Storytelling?", console_platform: "PC", developer: "Galactic Cafe" },
  119: { answer: "Fez", release_year: "2012", metacritic_score: "89%", genre: "Puzzle", console_platform: "Xbox 360", developer: "Polytron Corporation" },
  120: { answer: "Amnesia: The Dark Descent", release_year: "2010", metacritic_score: "85%", genre: "Horror", console_platform: "PC", developer: "Frictional Games" },
  121: { answer: "Professor Layton and the Curious Village", release_year: "2007 (JP), 2008 (Worldwide)", metacritic_score: "85%", genre: "Puzzle", console_platform: "Nintendo DS", developer: "Level-5" },
  // tuesday the 13th of September
  122: { answer: "Half-Life 2", release_year: "2004", metacritic_score: "96%", genre: "Shooter", console_platform: "PC", developer: "Valve" },
  123: { answer: "Assassin's Creed II", release_year: "2009", metacritic_score: "91%", genre: "Action/Adventure", console_platform: "PS3, Xbox 360", developer: "Ubisoft Montréal" },
  124: { answer: "The Simpsons: Hit & Run", release_year: "2003", metacritic_score: "78%", genre: "?Driving?", console_platform: "GameCube, PS2, Xbox", developer: "Radical Entertainment" },
  125: { answer: "Payday 2", release_year: "2013", metacritic_score: "79%", genre: "Shooter/Heist Sim?", console_platform: "PC, PS3, Xbox 360", developer: "Overkill Software" },
  126: { answer: "Metal Gear Solid 2: Sons of Liberty", release_year: "2001", metacritic_score: "96%", genre: "Action/Adventure/Stealth", console_platform: "PS2", developer: "Konami Computer Entertainment Japan" },
  127: { answer: "Disco Elysium", release_year: "2019", metacritic_score: "91%", genre: "RPG", console_platform: "PC", developer: "ZA/UM" },
  128: { answer: "Red Dead Redemption", release_year: "2010", metacritic_score: "95%", genre: "Action/Adventure", console_platform: "PS3, Xbox 360", developer: "Rockstar San Diego" },
  // tuesday the 20th of September
  129: { answer: "Rocket League", release_year: "2015", metacritic_score: "86%", genre: "🤔 Sports? 🤔", console_platform: "PC, PS4", developer: "Psyonix" },
  130: { answer: "ARK: Survival Evolved", release_year: "2017", metacritic_score: "70%", genre: "Action/Adventure", console_platform: "PC", developer: "Studio Wildcard" },
  131: { answer: "Genshin Impact", release_year: "2020", metacritic_score: "84%", genre: "RPG", console_platform: "PC, PS4, Mobile", developer: "miHoYo" },
  132: { answer: "Counter-Strike", release_year: "2000", metacritic_score: "88%", genre: "Shooter", console_platform: "PC", developer: "Valve" },
  133: { answer: "Mario Kart: Double Dash!!", release_year: "2003", metacritic_score: "87%", genre: "Racing", console_platform: "GameCube", developer: "Nintendo EAD" },
  134: { answer: "Undertale", release_year: "2015", metacritic_score: "92%", genre: "RPG", console_platform: "PC", developer: "Toby Fox" },
  135: { answer: "Cities: Skylines", release_year: "2015", metacritic_score: "85%", genre: "City Building", console_platform: "PC", developer: "Colossal Order" },
  // tuesday the 27th of September
  136: { answer: "Rust", release_year: "2013", metacritic_score: "69%", genre: "Action/Adventure", console_platform: "PC", developer: "Facepunch Studios" },
  137: { answer: "Viva Piñata", release_year: "2006", metacritic_score: "84%", genre: "Life Simulation", console_platform: "Xbox 360", developer: "Rare, Krome Studios" },
  138: { answer: "Overcooked!", release_year: "2016", metacritic_score: "81%", genre: "Arcade/Simulation/Complete Chaos", console_platform: "PC, PS4, Xbox One", developer: "Ghost Town Games" },
  139: { answer: "Team Fortress 2", release_year: "2007", metacritic_score: "92%", genre: "Shooter", console_platform: "PC", developer: "Valve" },
  140: { answer: "Hitman 3", release_year: "2021", metacritic_score: "87%", genre: "Stealth", console_platform: "PC, PS4, PS5, Xbox One, Xbox Series X/S", developer: "IO Interactive" },
  141: { answer: "Doom Eternal", release_year: "2020", metacritic_score: "88%", genre: "Shooter", console_platform: "PC, PS4, Xbox One", developer: "id Software" },
  142: { answer: "Hades", release_year: "2020", metacritic_score: "93%", genre: "Action/Adventure, Roguelike", console_platform: "PC, Switch", developer: "Supergiant Games" },
  // tuesday the 4th of october
  143: { answer: "Tunic", release_year: "2022", metacritic_score: "85%", genre: "Action/Adventure, RPG", console_platform: "PC, Xbox Series X/S, Xbox One", developer: "Andrew Shouldice" },
  144: { answer: "Ratchet & Clank", release_year: "2002", metacritic_score: "88%", genre: "3D Action Platformer", console_platform: "PS2", developer: "Insomniac Games" },
  145: { answer: "Elden Ring", release_year: "2022", metacritic_score: "96%", genre: "Action RPG", console_platform: "PC, PS4, PS5, Xbox One, Xbox Series X", developer: "From Software" },
  146: { answer: "No Man's Sky", release_year: "2016", metacritic_score: "71%", genre: "Action Adventure / Survival", console_platform: "PC, PS4", developer: "Hello Games" },
  147: { answer: "Crazy Taxi", release_year: "1999", metacritic_score: "80%", genre: "Racing", console_platform: "Arcade Cabinet", developer: "Hitmaker / Acclaim" },
  148: { answer: "Crysis", release_year: "2007", metacritic_score: "91%", genre: "Shooter", console_platform: "PC", developer: "Crytek" },
  149: { answer: "VVVVVV", release_year: "2010", metacritic_score: "81%", genre: "Platformer", console_platform: "PC", developer: "Terry Cavanagh" },
  // tuesday the 11th of october
  150: { answer: "Starcraft", release_year: "1998", metacritic_score: "88%", genre: "RTS", console_platform: "PC", developer: "Blizzard Entertainment" },
  151: { answer: "Fall Guys", release_year: "2020", metacritic_score: "81%", genre: "Party", console_platform: "PC, PS4", developer: "Mediatronic" },
  152: { answer: "Mother 3", release_year: "2006", metacritic_score: "Not scored (JP exclusive release)", genre: "RPG", console_platform: "GBA", developer: "Brownie Brown / HAL Laboratory" },
  153: { answer: "Journey", release_year: "2012", metacritic_score: "92%", genre: "Action/Adventure/Platformer", console_platform: "PS3", developer: "Thatgamecompany" },
  154: { answer: "Silent Hill 2", release_year: "2001", metacritic_score: "89%", genre: "Survival Horror", console_platform: "PS2, Xbox", developer: "Team Silent (Konami Computer Entertainment Tokyo)" },
  155: { answer: "Tom Clancy's Splinter Cell: Chaos Theory", release_year: "2005", metacritic_score: "92%", genre: "Stealth Action", console_platform: "Xbox, PS2, GameCube, PC", developer: "Ubisoft Montreal" },
  156: { answer: "League of Legends", release_year: "2009", metacritic_score: "78%", genre: "MOBA", console_platform: "PC", developer: "Riot Games" },
  // tuesday the 18th of october 
  157: { answer: "Borderlands", release_year: "2009", metacritic_score: "84%", genre: "Shooter", console_platform: "PC, PS3, Xbox 360", developer: "Gearbox Software" },
  158: { answer: "FEAR: First Encounter Assault Recon", release_year: "2005", metacritic_score: "88%", genre: "Psychological Horror / FPS", console_platform: "PC, Xbox 360, PS3", developer: "Monolith Productions" },
  159: { answer: "Twisted Metal", release_year: "1995", metacritic_score: "Too old for metacritic!", genre: "Vehicular combat", console_platform: "PlayStation", developer: "SingleTrac" },
  160: { answer: "Killzone 2", release_year: "2009", metacritic_score: "91%", genre: "Shooter", console_platform: "PS3", developer: "Guerilla Games" },
  161: { answer: "Luigi's Mansion", release_year: "2001", metacritic_score: "78%", genre: "Action/Adventure", console_platform: "GameCube", developer: "Nintendo" },
  162: { answer: "BioShock", release_year: "2007", metacritic_score: "96%", genre: "Shooter", console_platform: "PC, Xbox 360", developer: "2K Games & Irrational Games" },
  163: { answer: "Phasmophobia", release_year: "2020 (Early Access)", metacritic_score: "Not Yet Rated", genre: "Horror/Simulator", console_platform: "PC", developer: "Kinetic Games" },
  // tuesday the 25th of october
  164: { answer: "Tomb Raider", release_year: "2013", metacritic_score: "87%", genre: "Action/Adventure", console_platform: "PC, PS3, Xbox 360", developer: "Crystal Dynamics" },
  165: { answer: "Unreal Tournament", release_year: "1999", metacritic_score: "92%", genre: "Shooter", console_platform: "PC", developer: "Epic Games" },
  166: { answer: "Ori and the Blind Forest", release_year: "2015", metacritic_score: "88%", genre: "Platformer", console_platform: "Xbox One, PC", developer: "Moon Studios" },
  167: { answer: "Friday the 13th: The Game", release_year: "2017", metacritic_score: "61%", genre: "Survival Horror/Asymmetric Multiplayer", console_platform: "PC, PS4, Xbox One", developer: "IllFonic" },
  168: { answer: "Crypt of the NecroDancer", release_year: "2015", metacritic_score: "87%", genre: "Roguelike/Rhythm", console_platform: "PC", developer: "Brace Yourself Games" },
  169: { answer: "Manhunt", release_year: "2003", metacritic_score: "76%", genre: "Stealth / Horror", console_platform: "PS2", developer: "Rockstar North" },
  170: { answer: "Five Nights at Freddy's", release_year: "2014", metacritic_score: "78%", genre: "Horror", console_platform: "PC", developer: "Scott Cawthorn" },
  // tuesday the 1st of november
  171: { answer: "L.A. Noire", release_year: "2011", metacritic_score: "89%", genre: "Action/Adventure", console_platform: "PS3, Xbox 360, PC", developer: "Team Bondi" },
  172: { answer: "Castlevania: Symphony of the Night", release_year: "1997", metacritic_score: "93%", genre: "Action RPG / Platform Adventure", console_platform: "PS1", developer: "Konami Computer Entertainment Tokyo" },
  173: { answer: "Age of Empires II: The Age of Kings", release_year: "1999", metacritic_score: "92%", genre: "Real-Time Strategy", console_platform: "PC", developer: "Ensemble Studios" },
  174: { answer: "Battlefield 2", release_year: "2005", metacritic_score: "91%", genre: "Shooter", console_platform: "PC", developer: "DICE" },
  175: { answer: "Prince of Persia: The Sands of Time", release_year: "2003", metacritic_score: "92%", genre: "Action/Adventure", console_platform: "GBA, PS2, GameCube, Xbox, PC", developer: "Ubisoft Montreal" },
  176: { answer: "Darkest Dungeon", release_year: "2016", metacritic_score: "84%", genre: "Roguelite RPG", console_platform: "PC", developer: "Red Hook Studios" },
  177: { answer: "Sonic the Hedgehog", release_year: "1991", metacritic_score: "Too old for metacritic!", genre: "Platformer", console_platform: "Genesis", developer: "Sonic Team" },
  // tuesday the 8th of november
  178: { answer: "System Shock 2", release_year: "1999", metacritic_score: "92%", genre: "Sci-Fi Action/Adventure", console_platform: "PC", developer: "Ken Levine, Irrational Games, Looking Glass Studios" },
  179: { answer: "Spelunky", release_year: "2012 (or 2008 in origin.. but practically speaking, 2012)", metacritic_score: "87%", genre: "Platformer/Roguelike", console_platform: "Xbox 360 (or PC sort of...)", developer: "Mossmouth" },
  // 180: { answer: "", release_year: "", metacritic_score: "", genre: "", console_platform: "", developer: "" },
  // 181: { answer: "", release_year: "", metacritic_score: "", genre: "", console_platform: "", developer: "" },
  // 182: { answer: "", release_year: "", metacritic_score: "", genre: "", console_platform: "", developer: "" },
  // 183: { answer: "", release_year: "", metacritic_score: "", genre: "", console_platform: "", developer: "" },
  // 184: { answer: "", release_year: "", metacritic_score: "", genre: "", console_platform: "", developer: "" },
  // tuesday the 14th of november
  // 185: { answer: "", release_year: "", metacritic_score: "", genre: "", console_platform: "", developer: "" },
};

const previousGames = [];

for (var i = 1; i < realGame; i++)
{
  let success = false;
  const prevDaysAnswer = answers[i].answer.trim().toLowerCase();
  // console.log(`Game for day #${i}: ${prevDaysAnswer}`);
  const g1 = localStorage.getItem(`${i}_guess1`);
  const g2 = localStorage.getItem(`${i}_guess2`);
  const g3 = localStorage.getItem(`${i}_guess3`);
  const g4 = localStorage.getItem(`${i}_guess4`);
  const g5 = localStorage.getItem(`${i}_guess5`);
  const g6 = localStorage.getItem(`${i}_guess6`);

  if (g1 === null)
  {
    previousGames.push({ day: i, result: 'unplayed', guesses: 0, answer: prevDaysAnswer, cubes: ['q','q','q','q','q','q'], skippedAll: false });
    continue;
  }
  //            [ Show a question mark ]     [ Show a fail cube ------------------------------------------------------------------------- ]     [ Show a success cube ---------------------------------------------------- ]
  const c1 = g1 === null ? 'q'      :     (g1 === 'Skipped!' || g1.trim().toLowerCase() !== prevDaysAnswer.trim().toLowerCase()) ? 'f' :     g1.trim().toLowerCase() === prevDaysAnswer.trim().toLowerCase() ? 's' : 'f';
  const c2 = g2 === null ? 'q'      :     (g2 === 'Skipped!' || g2.trim().toLowerCase() !== prevDaysAnswer.trim().toLowerCase()) ? 'f' :     g2.trim().toLowerCase() === prevDaysAnswer.trim().toLowerCase() ? 's' : 'f';
  const c3 = g3 === null ? 'q'      :     (g3 === 'Skipped!' || g3.trim().toLowerCase() !== prevDaysAnswer.trim().toLowerCase()) ? 'f' :     g3.trim().toLowerCase() === prevDaysAnswer.trim().toLowerCase() ? 's' : 'f';
  const c4 = g4 === null ? 'q'      :     (g4 === 'Skipped!' || g4.trim().toLowerCase() !== prevDaysAnswer.trim().toLowerCase()) ? 'f' :     g4.trim().toLowerCase() === prevDaysAnswer.trim().toLowerCase() ? 's' : 'f';
  const c5 = g5 === null ? 'q'      :     (g5 === 'Skipped!' || g5.trim().toLowerCase() !== prevDaysAnswer.trim().toLowerCase()) ? 'f' :     g5.trim().toLowerCase() === prevDaysAnswer.trim().toLowerCase() ? 's' : 'f';
  const c6 = g6 === null ? 'q'      :     (g6 === 'Skipped!' || g6.trim().toLowerCase() !== prevDaysAnswer.trim().toLowerCase()) ? 'f' :     g6.trim().toLowerCase() === prevDaysAnswer.trim().toLowerCase() ? 's' : 'f';

  if (g1 !== null && g1.trim().toLowerCase() === prevDaysAnswer) { previousGames.push({ day: i, result: 'success', answer: prevDaysAnswer, cubes: [c1,c2,c3,c4,c5,c6], skippedAll: false }); success = true; }
  if (g2 !== null && g2.trim().toLowerCase() === prevDaysAnswer) { previousGames.push({ day: i, result: 'success', answer: prevDaysAnswer, cubes: [c1,c2,c3,c4,c5,c6], skippedAll: false }); success = true; }
  if (g3 !== null && g3.trim().toLowerCase() === prevDaysAnswer) { previousGames.push({ day: i, result: 'success', answer: prevDaysAnswer, cubes: [c1,c2,c3,c4,c5,c6], skippedAll: false }); success = true; }
  if (g4 !== null && g4.trim().toLowerCase() === prevDaysAnswer) { previousGames.push({ day: i, result: 'success', answer: prevDaysAnswer, cubes: [c1,c2,c3,c4,c5,c6], skippedAll: false }); success = true; }
  if (g5 !== null && g5.trim().toLowerCase() === prevDaysAnswer) { previousGames.push({ day: i, result: 'success', answer: prevDaysAnswer, cubes: [c1,c2,c3,c4,c5,c6], skippedAll: false }); success = true; }
  if (g6 !== null && g6.trim().toLowerCase() === prevDaysAnswer) { previousGames.push({ day: i, result: 'success', answer: prevDaysAnswer, cubes: [c1,c2,c3,c4,c5,c6], skippedAll: false }); success = true; }

  if (success !== true)
  {
    previousGames.push({ day: i, result: 'fail', guesses: 6, answer: prevDaysAnswer, cubes: [c1,c2,c3,c4,c5,c6], skippedAll: [g1,g2,g3,g4,g5,g6].every((g) => g === 'Skipped!') });
  }
}

// console.log(previousGames);

const answer = answers[GAME].answer;

const guess2Hint_YearReleased = answers[GAME].release_year;
const guess3Hint_MetacriticScore = answers[GAME].metacritic_score;
const guess4Hint_Genre = answers[GAME].genre;
const guess5Hint_ConsolePlatform = answers[GAME].console_platform;
const guess6Hint_Developer = answers[GAME].developer;

const saveLocalStorageItem = (itemName, itemValue) => {
  return localStorage.setItem(`${GAME}_${itemName}`, itemValue);
};

const deleteLocalStorageItem = (itemName) => {
  return localStorage.removeItem(`${GAME}_${itemName}`);
};

const resetState = () => {
  deleteLocalStorageItem("guess1");
  deleteLocalStorageItem("guess2");
  deleteLocalStorageItem("guess3");
  deleteLocalStorageItem("guess4");
  deleteLocalStorageItem("guess5");
  deleteLocalStorageItem("guess6");
  deleteLocalStorageItem("gamestate");
  window.location.reload(true);
};

const shakeInput = async () => {
  const gameInput = document.querySelector('input.game-input');

  gameInput.classList.add("shakeme");
  await delay(500);
  gameInput.classList.remove("shakeme");
};

const loadLocalStorageItem = (itemName) => {
  return localStorage.getItem(`${GAME}_${itemName}`);
}

const savedState = loadLocalStorageItem("gamestate");

let initialGameState;
let initialGuess1Value;
let initialGuess2Value;
let initialGuess3Value;
let initialGuess4Value;
let initialGuess5Value;
let initialGuess6Value;
let initialGuesses;

let initialPlayed = localStorage.getItem("totalplayed") == null ? 0 : parseInt(localStorage.getItem("totalplayed"));

let initialWon = localStorage.getItem("totalwon") == null ? 0 : parseInt(localStorage.getItem("totalwon"));

let initialWinPercentage = Math.floor((initialWon / initialPlayed) * 100);

let initialCurrentStreak = localStorage.getItem("currentstreak") == null ? 0 : parseInt(localStorage.getItem("currentstreak"));

let initialMaxStreak = localStorage.getItem("maxstreak") == null ? 0 : parseInt(localStorage.getItem("maxstreak"));

// console.log("Total played:", initialPlayed);
// console.log("Total won:", initialWon);
// console.log(`Win percentage: ${initialWinPercentage}%`);
// console.log("Current streak:", initialCurrentStreak);
// console.log("Max streak:", initialMaxStreak);

let initialOnefers = localStorage.getItem("onefers") == null ? 0 : parseInt(localStorage.getItem("onefers"));
let initialTwofers = localStorage.getItem("twofers") == null ? 0 : parseInt(localStorage.getItem("twofers"));
let initialThreefers = localStorage.getItem("threefers") == null ? 0 : parseInt(localStorage.getItem("threefers"));
let initialQuads = localStorage.getItem("quads") == null ? 0 : parseInt(localStorage.getItem("quads"));
let initialFivers = localStorage.getItem("fivers") == null ? 0 : parseInt(localStorage.getItem("fivers"));
let initialSixers = localStorage.getItem("sixers") == null ? 0 : parseInt(localStorage.getItem("sixers"));

// console.log("Saved onefers", initialOnefers);
// console.log("Saved twofers", initialTwofers);
// console.log("Saved threefers", initialThreefers);
// console.log("Saved quads", initialQuads);
// console.log("Saved fivers", initialFivers);
// console.log("Saved sixers", initialSixers);

let percentageDenominator = Math.max(initialOnefers, initialTwofers, initialThreefers, initialQuads, initialFivers, initialSixers);

// console.log("Percentage denominator:", percentageDenominator);

if (savedState == null) {
  // Populate new state
  saveLocalStorageItem("gamestate", "playing");
  initialGameState = "playing";
  initialGuess1Value = "";
  initialGuess2Value = "";
  initialGuess3Value = "";
  initialGuess4Value = "";
  initialGuess5Value = "";
  initialGuess6Value = "";
  initialGuesses = 0;
} else {
  // Load existing state
  initialGameState = loadLocalStorageItem("gamestate");
  initialGuess1Value = loadLocalStorageItem("guess1") || "";
  initialGuess2Value = loadLocalStorageItem("guess2") || "";
  initialGuess3Value = loadLocalStorageItem("guess3") || "";
  initialGuess4Value = loadLocalStorageItem("guess4") || "";
  initialGuess5Value = loadLocalStorageItem("guess5") || "";
  initialGuess6Value = loadLocalStorageItem("guess6") || "";
  initialGuesses = 0;
  if (initialGuess1Value != "") initialGuesses++;
  if (initialGuess2Value != "") initialGuesses++;
  if (initialGuess3Value != "") initialGuesses++;
  if (initialGuess4Value != "") initialGuesses++;
  if (initialGuess5Value != "") initialGuesses++;
  if (initialGuess6Value != "") initialGuesses++;
}

const assembleShareClipboardAndCopy = async (numberOfGuesses, lastGuessWasCorrect) => {
  let template = `#GuessTheGame #${GAME}

  🎮 guess1 guess2 guess3 guess4 guess5 guess6
  
  https://GuessThe.Game/`;

  template = numberOfGuesses == 1 ? template.replace("guess1", '🟩') : numberOfGuesses > 1 ? template.replace("guess1", '🟥') : template.replace("guess1", '⬛');
  template = numberOfGuesses == 2 ? template.replace("guess2", '🟩') : numberOfGuesses > 2 ? template.replace("guess2", '🟥') : template.replace("guess2", '⬛');
  template = numberOfGuesses == 3 ? template.replace("guess3", '🟩') : numberOfGuesses > 3 ? template.replace("guess3", '🟥') : template.replace("guess3", '⬛');
  template = numberOfGuesses == 4 ? template.replace("guess4", '🟩') : numberOfGuesses > 4 ? template.replace("guess4", '🟥') : template.replace("guess4", '⬛');
  template = numberOfGuesses == 5 ? template.replace("guess5", '🟩') : numberOfGuesses > 5 ? template.replace("guess5", '🟥') : template.replace("guess5", '⬛');
  template = numberOfGuesses == 6 && lastGuessWasCorrect ? template.replace("guess6", '🟩') : numberOfGuesses >= 6 ? template.replace("guess6", '🟥') : template.replace("guess6", '⬛');

  const textarea = document.createElement("textarea");
  textarea.value = template;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);

  const shareBtn = document.querySelector('button.share-results-btn');

  // console.log("Share btn:", shareBtn);
  shareBtn.innerHTML = "Copied!";
  await delay(1000);
  shareBtn.innerHTML = "Share Results"
};

const AutoComplete = (props) => {
  return (
    <>
      <input
        className="game-input"
        type="text"
        placeholder="Search for a game"
        onChange={props.onChangeFn}
        onKeyDown={props.onKeyDownFn}
        value={props.inputValue}
      />
      {props.shouldShowSuggestions && props.inputValue && <SuggestionsListComponent filteredSuggestionsList={props.filteredSuggestionsList} activeIndex={props.activeSuggestionIndex} onClickFn={props.onClickFn} />}
    </>
  );
};

const SuggestionsListComponent = (props) => {
  return props.filteredSuggestionsList.length ? (
    <ul className="suggestions">
      {props.filteredSuggestionsList.map((suggestion, index) => {
        let className;
        // Flag the active suggestion with a class
        if (index === props.activeIndex) {
          className = "suggestion-active";
        }
        return (
          <li className={className} key={suggestion} onClick={props.onClickFn}>
            {suggestion}
          </li>
        );
      })}
    </ul>
  ) : (
    <ul className="suggestions">
      <li className="no-results" key={"No results found!"}>
        No results found!
      </li>
    </ul>
  );
};


let gameList = [];

const populateGameList = async () => {
  const gameListResult = await axios.get(`/data/games.json?day=${GAME}`);
  gameList = gameListResult.data;
};

populateGameList();

function App() {
  let [currentScreen, setCurrentScreen] = useState("current-game");
  let [gameState, setGameState] = useState(initialGameState);
  let [selectedImage, selectImage] = useState(1);
  let [guessInputValue, setGuessInputValue] = useState("");
  let [guess1, setGuess1] = useState(initialGuess1Value);
  let [guess2, setGuess2] = useState(initialGuess2Value);
  let [guess3, setGuess3] = useState(initialGuess3Value);
  let [guess4, setGuess4] = useState(initialGuess4Value);
  let [guess5, setGuess5] = useState(initialGuess5Value);
  let [guess6, setGuess6] = useState(initialGuess6Value);
  let [guesses, setGuesses] = useState(initialGuesses);
  let [showGuesses, setShowGuesses] = useState(false);
  let [currentModal, setModal] = useState("none");

  let [played, setPlayed] = useState(initialPlayed);
  let [won, setWon] = useState(initialWon);
  let [currentStreak, setCurrentStreak] = useState(initialCurrentStreak);
  let [maxStreak, setMaxStreak] = useState(initialMaxStreak);
  let [winPercentage, setWinPercentage] = useState(initialWinPercentage);

  let [onefers, setOnefers] = useState(initialOnefers);
  let [twofers, setTwofers] = useState(initialTwofers);
  let [threefers, setThreefers] = useState(initialThreefers);
  let [quads, setQuads] = useState(initialQuads);
  let [fivers, setFivers] = useState(initialFivers);
  let [sixers, setSixers] = useState(initialSixers);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);

  let [historyScreenState, setHistoryScreenState] = useState(previousGames);

  const toggleScreen = () => {
    if (currentScreen === "current-game")
    {
      setCurrentScreen("previous-games");
    } else {
      setCurrentScreen("current-game");
    }
  };

  const goToDay = (dayNumber) => {
    if (devMode) {
      const url = `http://localhost:3000?fpg=${dayNumber}`;
      console.log(`(dev mode) Forcibly switching to day #${dayNumber}!`);
      console.log(`Using URL: ${url}`);
      window.location.replace(url);
    } else {
      const url = `https://guessthe.game?fpg=${dayNumber}`;
      console.log(`(dev mode) Forcibly switching to day #${dayNumber}!`);
      console.log(`Using URL: ${url}`);
      window.location.replace(url);
    }
  };

  const onChange = (e) => {
    const userInput = e.target.value;
    setGuessInputValue(userInput);
    if (userInput.length <= 2) {
      setFilteredSuggestions(gameList);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
      return;
    };
    // Filter our suggestions that don't contain the user's input
    const unLinked = gameList.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onKeyDown = (key) => {
    if (key.keyCode === 13 || key.keyCode === 9) {
      setGuessInputValue(filteredSuggestions[activeSuggestionIndex])
      setFilteredSuggestions([])
    }
  }

  const onClick = (e) => {
    setFilteredSuggestions([]);
    setGuessInputValue(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  const toggleShowGuesses = () => {
    setShowGuesses(!showGuesses);
  };

  const changeImage = (newImageNum) => {
    if (guesses < (newImageNum - 1) && gameState == "playing") return;
    selectImage(newImageNum);
  };

  const skipGuess = () => {
    submitGuess(null, true);
  };

  const submitGuess = (e, isSkipGuess) => {
    let currentInputValue;

    // console.log("is it a skip guess?", isSkipGuess);
    // console.log("Current input value:", currentInputValue)
    // console.log("Guess input value:", guessInputValue);

    if (isSkipGuess != undefined) {
      currentInputValue = "Skipped!"
    }
    else {
      currentInputValue = guessInputValue;
    }

    if (guessInputValue == '' && isSkipGuess !== true) return;

    if (guess1 == '') {
      setGuess1(currentInputValue);
      saveLocalStorageItem("guess1", currentInputValue);
      setGuesses(1);

      if (currentInputValue.trim().toLowerCase() == answer.toLowerCase()) {
        setGameState("win");
        saveLocalStorageItem("gamestate", "win");

        let newOnefers = onefers + 1;
        localStorage.setItem("onefers", newOnefers);
        setOnefers(newOnefers);

        let newTotalPlayed = played + 1;
        localStorage.setItem("totalplayed", newTotalPlayed);
        setPlayed(newTotalPlayed);

        let newTotalWon = won + 1;
        localStorage.setItem("totalwon", newTotalWon);
        setWon(newTotalWon);

        let newWinPercentage = Math.floor((newTotalWon / newTotalPlayed) * 100);
        setWinPercentage(newWinPercentage);

        let newCurrentStreak = currentStreak + 1;
        localStorage.setItem("currentstreak", newCurrentStreak);
        setCurrentStreak(newCurrentStreak);

        if (newCurrentStreak > maxStreak) {
          let newMaxStreak = newCurrentStreak;
          localStorage.setItem("maxstreak", newMaxStreak);
          setMaxStreak(newMaxStreak);
        }
      }
      else {
        shakeInput();
        selectImage(2);
      }
    }
    else if (guess2 == '') {
      setGuess2(currentInputValue);
      saveLocalStorageItem("guess2", currentInputValue);
      setGuesses(2);

      if (currentInputValue.trim().toLowerCase() == answer.toLowerCase()) {
        setGameState("win");
        saveLocalStorageItem("gamestate", "win");

        let newTwofers = twofers + 1;
        localStorage.setItem("twofers", newTwofers);
        setTwofers(newTwofers);

        let newTotalPlayed = played + 1;
        localStorage.setItem("totalplayed", newTotalPlayed);
        setPlayed(newTotalPlayed);

        let newTotalWon = won + 1;
        localStorage.setItem("totalwon", newTotalWon);
        setWon(newTotalWon);

        let newWinPercentage = Math.floor((newTotalWon / newTotalPlayed) * 100);
        setWinPercentage(newWinPercentage);

        let newCurrentStreak = currentStreak + 1;
        localStorage.setItem("currentstreak", newCurrentStreak);
        setCurrentStreak(newCurrentStreak);

        if (newCurrentStreak > maxStreak) {
          let newMaxStreak = newCurrentStreak;
          localStorage.setItem("maxstreak", newMaxStreak);
          setMaxStreak(newMaxStreak);
        }
      }
      else {
        shakeInput();
        selectImage(3);
      }
    }
    else if (guess3 == '') {
      setGuess3(currentInputValue);
      saveLocalStorageItem("guess3", currentInputValue);
      setGuesses(3);

      if (currentInputValue.trim().toLowerCase() == answer.toLowerCase()) {
        setGameState("win");
        saveLocalStorageItem("gamestate", "win");

        let newThreefers = threefers + 1;
        localStorage.setItem("threefers", newThreefers);
        setThreefers(newThreefers);

        let newTotalPlayed = played + 1;
        localStorage.setItem("totalplayed", newTotalPlayed);
        setPlayed(newTotalPlayed);

        let newTotalWon = won + 1;
        localStorage.setItem("totalwon", newTotalWon);
        setWon(newTotalWon);

        let newWinPercentage = Math.floor((newTotalWon / newTotalPlayed) * 100);
        setWinPercentage(newWinPercentage);

        let newCurrentStreak = currentStreak + 1;
        localStorage.setItem("currentstreak", newCurrentStreak);
        setCurrentStreak(newCurrentStreak);

        if (newCurrentStreak > maxStreak) {
          let newMaxStreak = newCurrentStreak;
          localStorage.setItem("maxstreak", newMaxStreak);
          setMaxStreak(newMaxStreak);
        }
      }
      else {
        shakeInput();
        selectImage(4);
      }
    }
    else if (guess4 == '') {
      setGuess4(currentInputValue);
      saveLocalStorageItem("guess4", currentInputValue);
      setGuesses(4);

      if (currentInputValue.trim().toLowerCase() == answer.toLowerCase()) {
        setGameState("win");
        saveLocalStorageItem("gamestate", "win");

        let newQuads = quads + 1;
        localStorage.setItem("quads", newQuads);
        setQuads(newQuads);

        let newTotalPlayed = played + 1;
        localStorage.setItem("totalplayed", newTotalPlayed);
        setPlayed(newTotalPlayed);

        let newTotalWon = won + 1;
        localStorage.setItem("totalwon", newTotalWon);
        setWon(newTotalWon);

        let newWinPercentage = Math.floor((newTotalWon / newTotalPlayed) * 100);
        setWinPercentage(newWinPercentage);

        let newCurrentStreak = currentStreak + 1;
        localStorage.setItem("currentstreak", newCurrentStreak);
        setCurrentStreak(newCurrentStreak);

        if (newCurrentStreak > maxStreak) {
          let newMaxStreak = newCurrentStreak;
          localStorage.setItem("maxstreak", newMaxStreak);
          setMaxStreak(newMaxStreak);
        }
      }
      else {
        shakeInput();
        selectImage(5);
      }
    }
    else if (guess5 == '') {
      setGuess5(currentInputValue);
      saveLocalStorageItem("guess5", currentInputValue);
      setGuesses(5);

      if (currentInputValue.trim().toLowerCase() == answer.toLowerCase()) {
        setGameState("win");
        saveLocalStorageItem("gamestate", "win");

        let newFivers = fivers + 1;
        localStorage.setItem("fivers", newFivers);
        setFivers(newFivers);

        let newTotalPlayed = played + 1;
        localStorage.setItem("totalplayed", newTotalPlayed);
        setPlayed(newTotalPlayed);

        let newTotalWon = won + 1;
        localStorage.setItem("totalwon", newTotalWon);
        setWon(newTotalWon);

        let newWinPercentage = Math.floor((newTotalWon / newTotalPlayed) * 100);
        setWinPercentage(newWinPercentage);

        let newCurrentStreak = currentStreak + 1;
        localStorage.setItem("currentstreak", newCurrentStreak);
        setCurrentStreak(newCurrentStreak);

        if (newCurrentStreak > maxStreak) {
          let newMaxStreak = newCurrentStreak;
          localStorage.setItem("maxstreak", newMaxStreak);
          setMaxStreak(newMaxStreak);
        }
      }
      else {
        shakeInput();
        selectImage(6);
      }
    }
    else if (guess6 == '') {
      setGuess6(currentInputValue);
      saveLocalStorageItem("guess6", currentInputValue);
      setGuesses(6);

      if (currentInputValue.trim().toLowerCase() == answer.toLowerCase()) {
        setGameState("win");
        saveLocalStorageItem("gamestate", "win");

        let newSixers = sixers + 1;
        localStorage.setItem("sixers", newSixers);
        setSixers(newSixers);

        let newTotalPlayed = played + 1;
        localStorage.setItem("totalplayed", newTotalPlayed);
        setPlayed(newTotalPlayed);

        let newTotalWon = won + 1;
        localStorage.setItem("totalwon", newTotalWon);
        setWon(newTotalWon);

        let newWinPercentage = Math.floor((newTotalWon / newTotalPlayed) * 100);
        setWinPercentage(newWinPercentage);

        let newCurrentStreak = currentStreak + 1;
        localStorage.setItem("currentstreak", newCurrentStreak);
        setCurrentStreak(newCurrentStreak);

        if (newCurrentStreak > maxStreak) {
          let newMaxStreak = newCurrentStreak;
          localStorage.setItem("maxstreak", newMaxStreak);
          setMaxStreak(newMaxStreak);
        }
      }
      else {
        setGameState("lose");
        saveLocalStorageItem("gamestate", "lose");

        let newTotalPlayed = played + 1;
        localStorage.setItem("totalplayed", newTotalPlayed);
        setPlayed(newTotalPlayed);

        let newWinPercentage = Math.floor((won / newTotalPlayed) * 100);
        setWinPercentage(newWinPercentage);

        let newCurrentStreak = 0;
        localStorage.setItem("currentstreak", newCurrentStreak);
        setCurrentStreak(newCurrentStreak);
      }
    }

    setGuessInputValue('');
  };

  return (
    <div className="App">
      {currentModal != "none" && <div className="modal-bg" onClick={() => { setModal("none") }}></div>}
      {currentModal == "love" && <div className="modal love-modal">
        <div className="love-modal-inner">
          <button type="button" onClick={() => { setModal("none"); }}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="currentColor" fill-rule="evenodd" clipRule="evenodd"></path></svg>
          </button>

          <h3>Support Me</h3>

          <p>I hope you enjoy this daily puzzle game! If it has brightened your day considering buying me a coffee as thanks :) </p>

          <a href='https://ko-fi.com/V7V5CPCWN' target='_blank'>
            <img height='36' style={{ border: '0px', height: '36px' }} src={'https://cdn.ko-fi.com/cdn/kofi1.png?v=3'} border='0' alt='Buy Me a Coffee at ko-fi.com' />
          </a>


          <p className="submit-link">📝 Want to submit a game for one of the daily puzzles? <a href="https://forms.gle/oJs3EVgzXxuy2SKSA" target="_blank" rel="noreferrer noopener">Click here!</a></p>
        </div>
      </div>}

      {currentModal == "stats" && <div className="modal stats-modal">
        <div className="stats-modal-inner">
          <button type="button" onClick={() => { setModal("none"); }}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="currentColor" fill-rule="evenodd" clipRule="evenodd"></path></svg>
          </button>

          <h3>Stats</h3>

          <div className="stats-section">

            <div className="stat">
              <div className="stat-value">{played}</div>
              <div className="stat-label">Played</div>
            </div>

            <div className="stat">
              <div className="stat-value">{won}</div>
              <div className="stat-label">Won</div>
            </div>

            <div className="stat">
              <div className="stat-value">{isNaN(winPercentage) ? 0 : winPercentage}</div>
              <div className="stat-label">Win %</div>
            </div>

            <div className="stat">
              <div className="stat-value">{currentStreak}</div>
              <div className="stat-label">Current Streak</div>
            </div>

            <div className="stat">
              <div className="stat-value">{maxStreak}</div>
              <div className="stat-label">Max Streak</div>
            </div>

          </div>

          <h3 style={{ 'textAlign': 'center', 'marginTop': '10px' }}>Win Distribution</h3>

          <div className="guess-distribution">

            <div className="guess-distribution-row">
              <p>1</p>
              <div className="guess-row-bar" style={{ width: `${onefers / percentageDenominator * 100}%` }}>{onefers}</div>
            </div>

            <div className="guess-distribution-row">
              <p>2</p>
              <div className="guess-row-bar" style={{ width: `${twofers / percentageDenominator * 100}%` }}>{twofers}</div>
            </div>

            <div className="guess-distribution-row">
              <p>3</p>
              <div className="guess-row-bar" style={{ width: `${threefers / percentageDenominator * 100}%` }}>{threefers}</div>
            </div>

            <div className="guess-distribution-row">
              <p>4</p>
              <div className="guess-row-bar" style={{ width: `${quads / percentageDenominator * 100}%` }}>{quads}</div>
            </div>

            <div className="guess-distribution-row">
              <p>5</p>
              <div className="guess-row-bar" style={{ width: `${fivers / percentageDenominator * 100}%` }}>{fivers}</div>
            </div>

            <div className="guess-distribution-row">
              <p>6</p>
              <div className="guess-row-bar" style={{ width: `${sixers / percentageDenominator * 100}%` }}>{sixers}</div>
            </div>

          </div>
        </div>
      </div>}

      {currentModal == "about" && <div className="modal about-modal">
        <div className="about-modal-inner">
          <button type="button" onClick={() => { setModal("none"); }}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
          </button>

          <h3>About</h3>

          <p>GuessThe.Game is a daily puzzle game for video game enthusiasts. Inspired by <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank" rel="noreferrer noopener">Wordle</a>, <a href="https://heardle.app/" target="_blank" rel="noreferrer noopener">Heardle</a> and <a href="https://framed.wtf/" target="_blank" rel="noreferrer noopener">Framed.wtf</a>.</p>

          <p>Every day a new game is selected and 6 screenshots from the game (some gameplay, some UI, some cutscenes - not guaranteed to strictly be gameplay!) are presented to you one at a time.</p>

          <p>All image rights go to the game companies that own these games - no copyright infringement is intended! If you'd like your game removed, please contact me.</p>

          <p>I would love help choosing games and screenshots for the daily puzzles. If you'd like to choose a game for a future puzzle, reach out!</p>

          <p>You can reach me on Twitter (<a href="https://twitter.com/samueldev">@samueldev</a>) or via <a href="mailto:samuel.dev.stiles@gmail.com">email</a></p>

          <p className="submit-link">📝 Want to submit a game for one of the daily puzzles? <a href="https://forms.gle/oJs3EVgzXxuy2SKSA" target="_blank" rel="noreferrer noopener">Click here!</a></p>
        </div>
      </div>}

      {currentModal == "howtoplay" && <div className="modal howtoplay-modal">
        <div className="howtoplay-modal-inner">
          <button type="button" onClick={() => { setModal("none"); }}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
          </button>

          <h3>How To Play</h3>

          <p>Search for the game that you think the shown screenshot belongs to.</p>
          <p>All 6 screenshots belong to the same game!</p>
          <p>If you get it wrong, we will reveal an additional screenshot of the game to help you.</p>
          <p>You get 6 guesses in total.</p>
          <p>Good luck!</p>
          <p className="submit-link">📝 Want to submit a game for one of the daily puzzles? <a href="https://forms.gle/oJs3EVgzXxuy2SKSA" target="_blank" rel="noreferrer noopener">Click here!</a></p>
        </div>
      </div>}

      <header className="Header">

        <h1 className="Logo"><span>🎮</span> GuessThe.Game</h1>

        <button type="button" className="love-btn" onClick={() => { setModal("love") }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path>
          </svg>
        </button>

        <button type="button" className="stats-btn" onClick={() => { setModal("stats") }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
          </svg>
        </button>

        <button type="button" className="about-btn" onClick={() => { setModal("about") }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </button>

        <button type="button" className="how-to-play-btn" onClick={() => { setModal("howtoplay") }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </button>

      </header>

      {devMode && <button style={{ marginBottom: "15px" }} onClick={() => { resetState(); }}>Reset State</button>}

      {devMode && <button style={{ marginBottom: "15px" }} onClick={() => { toggleScreen(); }}>Swap Screen</button>}

      {currentScreen === "current-game" && <div className="current-game">

        <div className="Screenshots">
          <div className="image-area">
            <div className="game-image" style={{ visibility: selectedImage == 1 ? 'visible' : 'hidden', backgroundImage: `url(./games/${GAME}/1.jpg)` }} alt="Screenshot 1"></div>
            <div className="game-image" style={{ visibility: selectedImage == 2 ? 'visible' : 'hidden', backgroundImage: `url(./games/${GAME}/2.jpg)` }} alt="Screenshot 2"></div>
            {selectedImage == 2 && <div className="guess-hint">Metacritic score: {guess3Hint_MetacriticScore}</div>}
            <div className="game-image" style={{ visibility: selectedImage == 3 ? 'visible' : 'hidden', backgroundImage: `url(./games/${GAME}/3.jpg)` }} alt="Screenshot 3"></div>
            {selectedImage == 3 && <div className="guess-hint">Original Platform: {guess5Hint_ConsolePlatform}</div>}
            <div className="game-image" style={{ visibility: selectedImage == 4 ? 'visible' : 'hidden', backgroundImage: `url(./games/${GAME}/4.jpg)` }} alt="Screenshot 4"></div>
            {selectedImage == 4 && <div className="guess-hint">Genre: {guess4Hint_Genre}</div>}
            <div className="game-image" style={{ visibility: selectedImage == 5 ? 'visible' : 'hidden', backgroundImage: `url(./games/${GAME}/5.jpg)` }} alt="Screenshot 5"></div>
            {selectedImage == 5 && <div className="guess-hint">Released: {guess2Hint_YearReleased}</div>}
            <div className="game-image" style={{ visibility: selectedImage == 6 ? 'visible' : 'hidden', backgroundImage: `url(./games/${GAME}/6.jpg)` }} alt="Screenshot 6"></div>
            {selectedImage == 6 && <div className="guess-hint">Developer: {guess6Hint_Developer}</div>}
          </div>
          <div className="image-selector">
            <button onClick={() => { changeImage(1) }} className={selectedImage == 1 ? 'active' : ''}>1</button>
            <button onClick={() => { changeImage(2) }} className={selectedImage == 2 ? 'active' : gameState == "playing" && guesses <= 0 ? 'locked' : ''}>2</button>
            <button onClick={() => { changeImage(3) }} className={selectedImage == 3 ? 'active' : gameState == "playing" && guesses <= 1 ? 'locked' : ''}>3</button>
            <button onClick={() => { changeImage(4) }} className={selectedImage == 4 ? 'active' : gameState == "playing" && guesses <= 2 ? 'locked' : ''}>4</button>
            <button onClick={() => { changeImage(5) }} className={selectedImage == 5 ? 'active' : gameState == "playing" && guesses <= 3 ? 'locked' : ''}>5</button>
            <button onClick={() => { changeImage(6) }} className={selectedImage == 6 ? 'active' : gameState == "playing" && guesses <= 4 ? 'locked' : ''}>6</button>
          </div>
        </div>

        {gameState != "playing" && <div className="result">
          {gameState == "win" && <h2 className="win-msg">You nailed it!</h2>}
          {gameState == "lose" && <h2 className="lose-msg" style={{ textAlign: 'center' }}>Oh no! Better luck next time.</h2>}
          <h3 style={{ textAlign: 'center' }}>The answer was: <div className="answer">{answer}</div></h3>
          <div className="emoji-track">
            <div className="guess-cube" className={guesses == 1 ? 'guess-cube success' : guesses >= 1 ? 'guess-cube fail' : 'guess-cube'}></div>
            <div className="guess-cube" className={guesses == 2 ? 'guess-cube success' : guesses >= 2 ? 'guess-cube fail' : 'guess-cube'}></div>
            <div className="guess-cube" className={guesses == 3 ? 'guess-cube success' : guesses >= 3 ? 'guess-cube fail' : 'guess-cube'}></div>
            <div className="guess-cube" className={guesses == 4 ? 'guess-cube success' : guesses >= 4 ? 'guess-cube fail' : 'guess-cube'}></div>
            <div className="guess-cube" className={guesses == 5 ? 'guess-cube success' : guesses >= 5 ? 'guess-cube fail' : 'guess-cube'}></div>
            <div className="guess-cube" className={guesses == 6 && answer.toLowerCase() == guess6.toLowerCase() ? 'guess-cube success' : guesses >= 6 ? 'guess-cube fail' : 'guess-cube'}></div>
          </div>
          <button className="share-results-btn" onClick={() => { assembleShareClipboardAndCopy(guesses, guess6.trim().toLowerCase() === answer.trim().toLowerCase()) }}>Share Results</button>

          <p className="end-of-game-guesses-summary-label" onClick={() => { toggleShowGuesses() }}>{showGuesses ? 'Hide' : 'Show'} Guesses</p>
          {showGuesses && guess1 != '' && <div className="end-of-game-guesses-summary">{guess1.trim().toLowerCase() == answer.trim().toLowerCase() && '✅'}{guess1.trim().toLowerCase() != answer.trim().toLowerCase() && '❌'}{guess1}</div>}
          {showGuesses && guess2 != '' && <div className="end-of-game-guesses-summary">{guess2.trim().toLowerCase() == answer.trim().toLowerCase() && '✅'}{guess2.trim().toLowerCase() != answer.trim().toLowerCase() && '❌'}{guess2}</div>}
          {showGuesses && guess3 != '' && <div className="end-of-game-guesses-summary">{guess3.trim().toLowerCase() == answer.trim().toLowerCase() && '✅'}{guess3.trim().toLowerCase() != answer.trim().toLowerCase() && '❌'}{guess3}</div>}
          {showGuesses && guess4 != '' && <div className="end-of-game-guesses-summary">{guess4.trim().toLowerCase() == answer.trim().toLowerCase() && '✅'}{guess4.trim().toLowerCase() != answer.trim().toLowerCase() && '❌'}{guess4}</div>}
          {showGuesses && guess5 != '' && <div className="end-of-game-guesses-summary">{guess5.trim().toLowerCase() == answer.trim().toLowerCase() && '✅'}{guess5.trim().toLowerCase() != answer.trim().toLowerCase() && '❌'}{guess5}</div>}
          {showGuesses && guess6 != '' && <div className="end-of-game-guesses-summary">{guess6.trim().toLowerCase() == answer.trim().toLowerCase() && '✅'}{guess6.trim().toLowerCase() != answer.trim().toLowerCase() && '❌'}{guess6}</div>}
          <p className="kofi-link">❤️ GuessTheGame? <a href="https://ko-fi.com/guessthegame" target="_blank" rel="noreferrer noopener">Buy me a coffee!</a></p>
          {/* <p className="kofi-link miss-a-day-link">Miss a day? <a href="https://guessthe.game/past.html">Replay past days here</a></p> */}
          <div className="socials">
            <a href="https://twitter.com/samueldev" className="twitter" target="_blank" rel="noreferrer noopener">
              <svg className="twitter" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" /></svg>
            </a>
          </div>



          <h3>Did you miss a day?</h3>
          <button className="share-results-btn" onClick={() => { toggleScreen() }}>Replay Previous Days Here!</button>

        </div>}

        {gameState != "win" && gameState != "lose" && guesses < 5 && <p className="guesses-remaining">{6 - guesses} {6 - guesses == 1 ? 'guess' : 'guesses'} remaining!</p>}
        {gameState != "win" && gameState != "lose" && guesses >= 5 && <p className="guesses-remaining">Last guess!</p>}

        {gameState == "playing" && <div className="PlayArea">
          {gameState == "playing" && <div className="input-area">

            <AutoComplete
              suggestions={gameList}
              onChangeFn={onChange}
              onKeyDownFn={onKeyDown}
              onClickFn={onClick}
              activeSuggestionIndex={activeSuggestionIndex}
              filteredSuggestionsList={filteredSuggestions}
              inputValue={guessInputValue}
              shouldShowSuggestions={showSuggestions}
            />

            <div className="play-area-btns">
              <button type="button" className="play-area-submit" onClick={submitGuess}>Submit</button>
              <button type="button" className="play-area-skip" onClick={skipGuess}>Skip &#x23E9;&#xFE0E;</button>
            </div>
          </div>}
          {guess1 != '' && <div className="guess-result">{guess1 == answer && '✅'}{guess1 != answer && '❌'}{guess1}</div>}
          {guess2 != '' && <div className="guess-result">{guess2 == answer && '✅'}{guess2 != answer && '❌'}{guess2}</div>}
          {guess3 != '' && <div className="guess-result">{guess3 == answer && '✅'}{guess3 != answer && '❌'}{guess3}</div>}
          {guess4 != '' && <div className="guess-result">{guess4 == answer && '✅'}{guess4 != answer && '❌'}{guess4}</div>}
          {guess5 != '' && <div className="guess-result">{guess5 == answer && '✅'}{guess5 != answer && '❌'}{guess5}</div>}
          {guess6 != '' && <div className="guess-result">{guess6 == answer && '✅'}{guess6 != answer && '❌'}{guess6}</div>}
        </div>}

      </div>}

      {currentScreen === "previous-games" && <div className="previous-games">
        
        <div className="back-to-today">
          <button className="share-results-btn" onClick={() => { toggleScreen() }}>Back To Todays Game</button>
        </div>

        <div className="prev-game-legend">
          <div className="emoji-track qmark-emojitrack"><span className="unplayed-qmark">❓</span><span>&nbsp;=&nbsp;Unplayed</span></div>

          <div className="emoji-track"><div className="guess-cube"></div><span>&nbsp;=&nbsp;Skipped</span></div>

          <div className="emoji-track"><div className="guess-cube fail"></div><span>&nbsp;=&nbsp;Incorrect</span></div>

          <div className="emoji-track"><div className="guess-cube success"></div><span>&nbsp;=&nbsp;Correct</span></div>
        </div>

        {historyScreenState.map((prevGame, index) => 
          <div className="prev-game-result-row" key={index} onClick={() => { goToDay(prevGame.day); }}>

            <div className="prev-game-day-number">#{prevGame.day}</div>

            <div className="emoji-track prev-game-emoji-track">

              {prevGame.result === 'unplayed' && <>
                <span className="unplayed-qmark">❓</span>
                <span className="unplayed-qmark">❓</span>
                <span className="unplayed-qmark">❓</span>
                <span className="unplayed-qmark">❓</span>
                <span className="unplayed-qmark">❓</span>
                <span className="unplayed-qmark">❓</span>
              </>}

              {(prevGame.result === 'success' || prevGame.result === 'fail') && <>

                {prevGame.cubes[0] === 'f' ? <div className="guess-cube fail"></div> : prevGame.cubes[0] === 'q' ? <div className="guess-cube"></div> : prevGame.cubes[0] === 's' ? <div className="guess-cube success"></div> : <></>}
                {prevGame.cubes[1] === 'f' ? <div className="guess-cube fail"></div> : prevGame.cubes[1] === 'q' ? <div className="guess-cube"></div> : prevGame.cubes[1] === 's' ? <div className="guess-cube success"></div> : <></>}
                {prevGame.cubes[2] === 'f' ? <div className="guess-cube fail"></div> : prevGame.cubes[2] === 'q' ? <div className="guess-cube"></div> : prevGame.cubes[2] === 's' ? <div className="guess-cube success"></div> : <></>}
                {prevGame.cubes[3] === 'f' ? <div className="guess-cube fail"></div> : prevGame.cubes[3] === 'q' ? <div className="guess-cube"></div> : prevGame.cubes[3] === 's' ? <div className="guess-cube success"></div> : <></>}
                {prevGame.cubes[4] === 'f' ? <div className="guess-cube fail"></div> : prevGame.cubes[4] === 'q' ? <div className="guess-cube"></div> : prevGame.cubes[4] === 's' ? <div className="guess-cube success"></div> : <></>}
                {prevGame.cubes[5] === 'f' ? <div className="guess-cube fail"></div> : prevGame.cubes[5] === 'q' ? <div className="guess-cube"></div> : prevGame.cubes[5] === 's' ? <div className="guess-cube success"></div> : <></>}

              </>}

            </div>


            {prevGame.result === 'unplayed' && <div className="prev-game-result pending">⌛<span className="result-text-supplementary"> Unplayed! 🚨</span></div>}

            {prevGame.result === 'success' && <div className="prev-game-result success">✅<span className="result-text-supplementary"> Success! 🦄</span></div>}

            {prevGame.result === 'fail' && prevGame.skippedAll === true && <div className="prev-game-result fail">❌<span className="result-text-supplementary"> Skipped all 😔</span></div>}

            {prevGame.result === 'fail' && prevGame.skippedAll === false && <div className="prev-game-result fail">❌<span className="result-text-supplementary"> Failed 😔</span></div>}

            <div className="play-prev-game-btn">
              <button className="play-prev-day-btn">Play <span className="day-text">Day</span> #{prevGame.day}!</button>
            </div>

          </div>
        )}

      </div>}

      <div className="footer" style={{ marginTop: '10px' }}>
        <p className="countdown-label">New Game In:</p>
        <Countdown className="countdown-to-next-game" date={tomorrow} renderer={countdownRenderer} />
      </div>
    </div>
  );
}

export default App;
